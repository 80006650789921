import React, { Component } from "react";
import { serviceAxios as axios } from "../../bootstrap";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { compose, withProps } from "recompose";
import { Field, reduxForm, formValueSelector } from "redux-form";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import Unarchive from "@material-ui/icons/Unarchive";
import FormSwitch from "../Components/FormSwitch";
import withConnected from "utilities/withConnected";
import SelectClient from "./SelectClient";
import SelectTaxes from "../Reports/SelectSquareTaxes";
import SelectLocation from "../Reports/SelectSquareLocation";
import CategorySelect from "./CategorySelect";
import ProductChangelog from "./ProductChangelog";
import SelectStatus from "views/Reports/SelectStatus";
import { connect } from "react-redux";
import _ from "lodash";
import GuardedComponent from "components/Authorization/GuardedComponent";
import { InputAdornment } from "material-ui";
const required = (value) => (value ? undefined : "Required");
const skuFormatRule = (value = "") =>
  /^[a-zA-z]{3}-?\d{6}$/.test(value)
    ? undefined
    : "SKU Format Error. The SKU format is 3 Letters, followed by 6 digits";
const positiveNumbersOnly = (value= 0) => value < 0 ? "Only positive numbers are allowed" : undefined;

class EditProductForm extends Component {
  constructor(props) {
    super();
    this.handleLocationChange = this.handleLocationChange.bind(this);
  }

  componentDidMount() {
    const { rdx } = this.props;
    rdx.action.loadClients({enabled: 1});
    rdx.action.loadSquareTaxes();
    rdx.action.loadSquareLocations();
  }

  handleLocationChange(event, locationId) {
    const { change } = this.props;
    axios.get("/api/square/taxes").then((response) => {
      const defaultTax = locationId
        ? response?.data?.data?.filter(
            (tax) =>
              tax.present_at_all_locations ||
              tax.present_at_location_ids.includes(locationId)
          )[0]
        : undefined;
      if (defaultTax) {
        change("square_tax_id", defaultTax.id);
      }
    });
  }

  render() {
    const {
      handleSubmit,
      submitting,
      image,
      clientName,
      isReservedPrice,
      changelog,
      clientCode,
    } = this.props;

    const lowEstimatePriceField = (
      <ItemGrid xs={12} sm={12} md={6}>
        <Field
          component={CustomInput}
          name="low_estimate"
          labelText="Low Estimate Price"
          validate={[required, positiveNumbersOnly]}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: "number"
          }}
          icon={
            <InputAdornment position="start">
              <span style={{marginTop: '6px'}}>$</span>
            </InputAdornment>
          }
          onFocus={e => e.target.select()}
        />
      </ItemGrid>
    );

    const highEstimatePriceField = (
      <ItemGrid xs={12} sm={12} md={6}>
        <Field
          component={CustomInput}
          name="high_estimate"
          type="number"
          labelText="High Estimate Price"
          validate={[required, positiveNumbersOnly]}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: "number"
          }}
          icon={
            <InputAdornment position="start">
              <span style={{marginTop: '6px'}}>$</span>
            </InputAdornment>
          }
          onFocus={e => e.target.select()}
        />
      </ItemGrid>
    );

    return (
      <ItemGrid xs={12} sm={12} md={12}>
        <IconCard
          icon={Unarchive}
          iconColor="rose"
          title="Edit Product"
          content={
            <>
              <div>
                <form onSubmit={handleSubmit}>
                  <GridContainer>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={6} md={6}>
                          <Field
                            component={CategorySelect}
                            name="category_id"
                          />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={6} md={6}>
                          <Field
                            component={CustomInput}
                            name="name"
                            type="text"
                            labelText="Product Name"
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          <Field
                            component={CustomInput}
                            name="description"
                            type="text"
                            labelText="Notes"
                            inputProps={{
                              multiline: true,
                              rows: 2,
                              maxrows: 4,
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                    <ItemGrid
                      xs={12}
                      sm={12}
                      md={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ margin: "auto" }}>
                        <Field
                          component={ImageUpload}
                          imageURL={image}
                          name="picture"
                        />
                      </div>
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={6}>
                      <GridContainer>
                        <ItemGrid xs={12} sm={12} md={12}></ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <Field
                            component={CustomInput}
                            name="sku"
                            type="text"
                            labelText="SKU *"
                            validate={[required, skuFormatRule]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <Field
                            component={SelectClient}
                            name="client_id"
                            clientName={clientName}
                            clientCode={clientCode}
                            validate={[required]}
                          />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <Field
                            component={SelectStatus}
                            name="status"
                            type="text"
                            labelText="Select Status *"
                            multiple={false}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <Field
                            component={SelectLocation}
                            name="square_location_id"
                            type="text"
                            labelText="Select Location *"
                            onChange={this.handleLocationChange}
                            validate={[required]}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <Field
                            component={CustomInput}
                            name="price"
                            type="number"
                            disabled={!!isReservedPrice}
                            labelText={
                              isReservedPrice ? "Reserved Price" : "Price"
                            }
                            formControlProps={{
                              fullWidth: true,
                              disabled: !!isReservedPrice,
                            }}
                            inputProps={{
                              type: "number",
                            }}
                          />
                        </ItemGrid>
                        {lowEstimatePriceField}
                        {highEstimatePriceField}
                        {/*
                      <ItemGrid xs={1} sm={1} md={1}>
                        <Field
                            component={StarCheckbox}
                            name="starred"
                            title="Highlight this product"
                            labelText=""
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                      </ItemGrid>
                      */}

                        <ItemGrid xs={12} sm={12} md={12}>
                          <Field
                            component={SelectTaxes}
                            name="square_tax_id"
                            type="text"
                            labelText="Taxes"
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </ItemGrid>
                        <ItemGrid xs={12} sm={12} md={12}>
                          Signed <Field component={FormSwitch} name="signed" />
                        </ItemGrid>
                      </GridContainer>
                    </ItemGrid>
                  </GridContainer>
                  <GuardedComponent scope={["products-edit"]}>
                    <Button
                      color="rose"
                      right
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </Button>
                  </GuardedComponent>
                </form>
              </div>
              {!_.isEmpty(changelog) && (
                <div style={{ marginTop: "70px" }}>
                  <ProductChangelog productChangelog={changelog} />
                </div>
              )}
            </>
          }
        />
      </ItemGrid>
    );
  }
}
const editProductSelector = formValueSelector("edit_product");
const mapStateToProps = (state) => {
  return {
    isReservedPrice: editProductSelector(state, "is_reserved_price") || false,
    price: editProductSelector(state, "price") || 0,
  };
};

export default compose(
  withProps((props) => {
    const { product, image } = props;
    return {
      initialValues: {
        name: product.name,
        sku: product.sku,
        category_id: product.category_id,
        description: product.description,
        notes: product.notes,
        price: product.price,
        is_reserved_price: product.is_reserved_price,
        client_id: product.client_id,
        signed: product.signed,
        status: product.status,
        code: product.code,
        starred: product.starred || false,
        square_tax_id: product.square_tax_id || "",
        square_location_id: product.square_location_id || "",
        picture: image,
        low_estimate: product.low_estimate,
        high_estimate: product.high_estimate,
      },
      clientName: product.clientName,
      changelog: product.changelog,
      clientCode: product.clientCode,
    };
  }),
  reduxForm({
    form: "edit_product",
    enableReinitialize: true,
  }),
  connect(mapStateToProps)
)(withConnected(EditProductForm));
