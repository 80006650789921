import React from 'react'
import { Switch, withStyles } from 'material-ui';
import FormControlLabel from "material-ui/Form/FormControlLabel";
import FormControl from "material-ui/Form/FormControl";

const styles = {
    greenBg: {
        backgroundColor: "green"
    },
    redBg: {
        backgroundColor: "gray"
    },
  };

class IsEnabledSwitch extends React.Component {
    state = {
        enabled: false
    }

    componentDidMount() {
        this.setState({ enabled: this.props.input.value })
    }

    valueChanged = (event, newValue) => {
        this.setState( {enabled: newValue} )
        this.props.input.onChange(newValue)
    }

    render() {
        return <>
            <FormControl>
                <FormControlLabel
                  control={
                      <Switch
                        checked={this.state.enabled} onChange={this.valueChanged}
                      />
                  }
                  label={this.state.enabled ? "The status is active" : "The status is inactive"}
                >
                </FormControlLabel>
            </FormControl>
        </>
    }

}

export default withStyles(styles)(IsEnabledSwitch)