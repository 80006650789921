import React from 'react';
import { compose } from 'recompose';
import { serviceAxios as axios } from '../../bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert'
import EditProductForm from './EditProductForm';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { BarLoader } from 'react-css-loaders';
import extractErrorMessage from '../../utilities/HttpErrorResponse';
import withStyles from "material-ui/styles/withStyles";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import { NotificationManager } from 'react-notifications';

class EditProduct extends React.Component {

    state = {
        alert: null,
        isLoadingProduct: false,
        submitting: false,
        product: null,
        image: null
    }

    componentDidMount() {
        document.title = 'EPOS - Edit Product'
        if (!this.state.isLoadingProduct) {
            this.getProduct();
        }
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }

    showAlert(message, title) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    title={title}
                    confirmBtnText="Continue"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                >
                    {message}
                </SweetAlert>
            )
        });
    }

    getImage = (id) => {
        axios.get(`/api/files/products/${id}/photo.jpg`, { responseType: 'blob' })
            .then(response => {
                if (response.data.size > 10) {
                    var data_url = URL.createObjectURL(response.data);
                    this.setState({ image: data_url });
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    getClient = async (clientId) => {
        if (clientId) {
            try {
                const clientResponse = await axios.get(`/api/clients/${clientId}`)
                const client = clientResponse.data.data
                this.setState(state => ({
                    product: { ...state.product, clientName: `${client.firstName} ${client.lastName}`, clientCode: client.code }
                }))
            } catch (error) {
                this.showAlert("Whose product?", "Failed to get product's owner data.")
            }
        }
    }

    getProduct = () => {
        const productId = this.props.match.params.id
        this.getImage(productId);
        this.setState({ isLoadingProduct: true })
        axios.get(`/api/products/${productId}`)
            .then(response => {
                this.setState({
                    product: response.data.data,
                    isLoadingProduct: false
                })
                this.getClient(response.data.data.client_id);
            })
            .catch((error) => {
                this.setState({ isLoadingProduct: false })
                this.showAlert(extractErrorMessage(error), "Getting Product Failed")
            });
    }

    updateProduct = async (json) => {
        if (Number(json.low_estimate) > Number(json.high_estimate)) {
            NotificationManager.warning(
              "Low Estimate Price cannot be greater than High Estimate Price",
              "Error"
            );
            return;
        }
        let productId = this.props.match.params.id
        this.setState({ submitting: true })

        const file = json.picture && json.picture.file
        delete json.picture

        try {
            let response = await axios.post(`/api/products/${productId}`, json)
            if (file === -1) {
                await axios.delete(`/api/files/products/${productId}/photo.jpg`);
            } else if (file) {
                await axios.post(`/api/files/products/${productId}/photo.jpg`
                    , file, {
                    headers: {
                        'Content-Type': file.type
                    }
                });
            } else {
                await axios.put(`/api/files/products/${productId}/photo.jpg`);
            }
            this.setState({
                product: response.data.data,
                submitting: false
            })
            if (response.data.data.square_id) {
                NotificationManager.success("Product Modified", "Success")
            } else {
                NotificationManager.warning("Product Modified (but not synchronized with Square)", "Warning")
            }
        } catch (error) {
            this.setState({ submitting: false })
            this.showAlert(extractErrorMessage(error), "Product Update Failed")
        }
    }

    render() {
        return (
            <div>
                { this.state.alert}
                { (this.state.isLoadingProduct || _.isNull(this.state.product)) ?
                    <BarLoader color="#d81b60" size={8} />
                    :
                    <>
                        <EditProductForm
                            product={this.state.product}
                            image={this.state.image}
                            submitting={this.state.submitting}
                            onSubmit={this.updateProduct}
                        />
                    </>
                }
            </div>
        )
    }
}

export default compose(
    withRouter,
    withStyles(buttonStyle)
)(EditProduct);