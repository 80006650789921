import React from "react";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import IconCard from "components/Cards/IconCard.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import PermIdentity from "@material-ui/icons/PermIdentity";
import { Field, reduxForm } from 'redux-form';
import ProfileValidator from 'validators/auth/ProfileValidator';
import PictureUpload from 'components/CustomUpload/PictureUpload.jsx';
import { compose, withProps } from 'recompose';
import IsEnabledSwitch from "../Components/IsEnabledSwitch";
import RoleSelect from "./RoleSelect";
import GuardedComponent from "components/Authorization/GuardedComponent";

const EditUserForm = (props) => {

    const {
        handleSubmit,
        submitting,
        image
    } = props;

    return (

        <ItemGrid xs={12} sm={12} md={12}>
            <IconCard
                icon={PermIdentity}
                iconColor="rose"
                title='Create User'
                content={
                    <div>
                        <form onSubmit={ handleSubmit }>
                            <GridContainer>
                                <ItemGrid xs={12} sm={12} md={5} style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                    <div style={{ margin: "auto" }}>
                                        <Field
                                            imageURL = {image}
                                            component={ PictureUpload }
                                            name = "picture" />
                                    </div>
                                </ItemGrid>
                                <ItemGrid xs={12} sm={12} md={7}>
                                    <GridContainer>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput } 
                                                name= "firstName"
                                                type= "text"
                                                labelText= "First Name"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }}
                                                />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput } 
                                                name="lastName"
                                                type= "text"
                                                labelText= "Last Name"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput }
                                                name= "email"
                                                type= "text"
                                                labelText= "Email"
                                                formControlProps= {{
                                                    fullWidth: true
                                                }} />
                                        </ItemGrid>                                        
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field component={ CustomInput } 
                                                name= "phone"
                                                type= "text"
                                                labelText= 'Phone'
                                                formControlProps= {{
                                                    fullWidth: true
                                                }}
                                            />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <Field
                                                component = { RoleSelect }
                                                name = "role_id" />
                                        </ItemGrid>
                                        <ItemGrid xs={12} sm={12} md={12}>
                                            <p>Status</p>
                                            <Field
                                                component = { IsEnabledSwitch }
                                                name = "enabled" />
                                        </ItemGrid>   
                                    </GridContainer>
                                </ItemGrid>
                            </GridContainer>
                            <GuardedComponent scope={['users-edit']}>
                                <Button color="rose" right type="submit" disabled={ submitting}>
                                    Submit
                                </Button>
                            </GuardedComponent>
                            <Clearfix />
                        </form>
                    </div>
                }
            />
        </ItemGrid>
    )
};

export default compose(
    withProps(({ user }) => {
        return {
            initialValues: {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phone,
                role_id: user.role_id,
                enabled: user.enabled
            }
        }
    }),
    reduxForm({
        form: 'edit_user',
        validate: (values) => {
            const validator = new ProfileValidator();
            const result = validator.validate(values);
            return result.valid ? {} : result.errors;
        }
    })
)(EditUserForm);